import { Api, Colors, SharedUtils, Spacing } from '@walter/shared'
import {
  Alert,
  Avatar,
  Button,
  ConfirmEmailModal,
  ConfirmPhoneNumberModal,
  Icon,
  Modal,
  NavBuilding,
  NavStyled,
  Pill,
  Popover,
  PopoverAlignment,
  PopoverContext,
  PopoverMenu,
  PopoverPosition,
  Search,
} from '@walter/shared-web'
import { matchSorter } from 'match-sorter'
import * as React from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AuthContext from '../../contexts/Auth'
import { useConversations } from '../../contexts/Conversations'
import CurrentUserContext from '../../contexts/CurrentUser'
import ProjectContext from '../../contexts/Project'
import PropertyContext from '../../contexts/Property'
import { t } from '../../utils/i18n'

const Properties = styled.div`
  display: flex;

  > * + * {
    margin-left: ${Spacing.small};
  }
`

const Meta = styled.div`
  display: flex;
  align-items: center;

  > * + * {
    margin-left: ${Spacing.small};
  }
`

const SearchWrap = styled.div`
  display: flex;
  margin-right: ${Spacing.medium};
`

const ManagingCompanyLogoWrapper = styled.div`
  margin-right: ${Spacing.medium};
  align-self: flex-start;
`

const MAX_EMERGENCY_CONTACTS = 3

export default function Nav() {
  const { propertyId } = useParams<{ propertyId: string }>()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const { logout, refetchCurrentUserQuery } = React.useContext(AuthContext)
  const { currentUser } = React.useContext(CurrentUserContext)
  const { currentProject } = React.useContext(ProjectContext)
  const { currentResidentType } = React.useContext(CurrentUserContext)
  const { uniqProperties, selectedProperty } = React.useContext(PropertyContext)
  const { unreadMessagesCountByPropertyIdMap } = useConversations()

  const [searchTextValue, setSearchTextValue] = React.useState('')
  const [confirmEmailModalisOpen, setConfirmEmailModalIsOpen] = React.useState(false)
  const [confirmPhoneNumberModalIsOpen, setConfirmPhoneNumberModalIsOpen] = React.useState(false)
  const [helpPopoverVisible, setHelpPopoverVisible] = React.useState<boolean>(false)

  const { setPopoverVisible: setPopoverContentVisible } = React.useContext(PopoverContext)

  const { data: { emergencyContacts = [] } = {} } = Api.useEmergencyContactsQuery({
    skip: !selectedProperty?.building?.project?.id,
    variables: { projectIds: selectedProperty?.building?.project?.id ? [selectedProperty.building.project.id] : [] },
  })

  const searchedProperties = React.useMemo(
    () => matchSorter(uniqProperties, searchTextValue, { keys: ['address.apartmentNumber'] }),
    [searchTextValue, uniqProperties],
  )

  function handlePropertyClick(propertyId?: string) {
    if (!propertyId) {
      throw new Error('Property id is missing')
    }
    const module = pathname.split('/')[2] || 'news'
    return () => {
      navigate(`/${propertyId}/${module}`)
    }
  }

  function handlePhoneConfirmed() {
    refetchCurrentUserQuery()
    setConfirmPhoneNumberModalIsOpen(false)
  }

  function handleEmailConfirmed() {
    refetchCurrentUserQuery()
    setConfirmEmailModalIsOpen(false)
  }

  return (
    <NavStyled.Container>
      <NavStyled.Wrap>
        <NavStyled.Group>
          {selectedProperty?.address && !!currentProject ? (
            <>
              <ManagingCompanyLogoWrapper>
                {currentProject.managingCompany.logo?.url ? (
                  <img
                    data-tip={currentProject.managingCompany.shortName}
                    style={{ height: 45 }}
                    src={currentProject.managingCompany.logo.url}
                  />
                ) : (
                  <NavStyled.Name>{currentProject?.managingCompany.shortName}</NavStyled.Name>
                )}
              </ManagingCompanyLogoWrapper>
              <NavStyled.Header>
                <NavStyled.Name>{currentProject?.name}</NavStyled.Name>
                <Meta>
                  <NavStyled.Project>{`${t('menu:unit-uppercase')} ${
                    selectedProperty.address.apartmentNumber
                  }`}</NavStyled.Project>
                  <Pill text={t(`residentType:${currentResidentType}`) as string} type="primary" />
                </Meta>
              </NavStyled.Header>
            </>
          ) : (
            <div style={{ minWidth: 392 }} />
          )}
          <NavStyled.NavList>
            <SearchWrap>
              <Search onTextChange={setSearchTextValue} />
            </SearchWrap>

            <Properties>
              {searchedProperties.map((property, index) => (
                <NavBuilding
                  key={index}
                  handleClick={handlePropertyClick(property?.id)}
                  name={`${t('navigation:unit')} ${property?.address?.apartmentNumber} - ${
                    property?.building?.project?.name
                  }`}
                  notifications={property?.id ? unreadMessagesCountByPropertyIdMap.get(property.id) ?? 0 : 0} // For now we only show notifications for chat
                  isActive={property?.id === propertyId}
                  initials={property?.address?.apartmentNumber ?? ''}
                />
              ))}
            </Properties>
          </NavStyled.NavList>
        </NavStyled.Group>

        <NavStyled.Group className="gap-2">
          {!!currentUser.email && !currentUser.hasConfirmedEmail && (
            <Alert
              onClick={() => setConfirmEmailModalIsOpen(true)}
              type="warning"
              title={t('auth:please-confirm-your-email')}
            />
          )}
          {!!currentUser.phone?.number && !currentUser.hasConfirmedPhone && (
            <Alert
              onClick={() => setConfirmPhoneNumberModalIsOpen(true)}
              type="warning"
              title={t('auth:please-confirm-your-phone-number')}
            />
          )}
          {emergencyContacts.length > 0 && (
            <Popover
              trigger={({ onClick }, forwardRef) => (
                <div className="flex flex-col items-center justify-end">
                  <button
                    ref={forwardRef as React.Ref<HTMLButtonElement>}
                    onClick={onClick}
                    className="bg-destructive rounded-full w-8 h-8 justify-center items-center flex hover:opacity-90 transition-opacity focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-destructive focus-visible:ring-offset-1"
                  >
                    <Icon icon="phone" size="small" color={Colors.white} />
                  </button>
                  <span className="text-[0.6rem] absolute select-none -bottom-3.5">{t('emergency')}</span>
                </div>
              )}
              position={PopoverPosition.BOTTOM}
              alignment={PopoverAlignment.RIGHT}
            >
              <div aria-live="assertive" className="bg-card rounded-md shadow-md p-2 border min-w-80">
                <p className="mb-0 py-2 px-2 text-sm font-bold">{t('for-any-emergency-contact-us-at')}</p>

                {emergencyContacts.slice(0, MAX_EMERGENCY_CONTACTS).map((contact, index) => {
                  return (
                    <EmergencyContact
                      key={`emergency-contact:${contact.id}`}
                      contact={contact}
                      isFirst={index === 0}
                      closePopover={() => {
                        setPopoverContentVisible(false)
                      }}
                    />
                  )
                })}
                {emergencyContacts.length > MAX_EMERGENCY_CONTACTS && (
                  <>
                    <div className="h-2" />
                    <Link
                      to={`/${propertyId}/contacts`}
                      onClick={() => {
                        setPopoverContentVisible(false)
                      }}
                    >
                      <div className="hover:bg-border/20 p-2 rounded group-focus-visible:bg-border/20 group-focus-visible:focus-visible:outline-none group-focus-visible:ring-2 group-focus-visible:ring-primary group-focus-visible:ring-offset-1">
                        <p className="mb-0 text-xs font-medium text-center">{t('view-all')}</p>
                      </div>
                    </Link>
                  </>
                )}
              </div>
            </Popover>
          )}
          <NavStyled.Profile>
            <Popover
              trigger={({ onClick }, forwardRef) => (
                <NavStyled.AvatarWrap ref={forwardRef as React.Ref<HTMLButtonElement>} onClick={onClick}>
                  <Avatar photo={currentUser.avatar?.url} name={`${currentUser?.firstName} ${currentUser?.lastName}`} />
                </NavStyled.AvatarWrap>
              )}
              position={PopoverPosition.BOTTOM}
              alignment={PopoverAlignment.RIGHT}
            >
              <PopoverMenu
                actions={[
                  {
                    label: t('navigation:profile'),
                    onClick: () => {
                      setPopoverContentVisible(false)
                      navigate(`/${propertyId}/profile`)
                    },
                  },
                  {
                    label: t('navigation:help'),
                    onClick: () => {
                      setPopoverContentVisible(false)
                      setHelpPopoverVisible(true)
                    },
                  },
                  {
                    label: t('navigation:logout'),
                    onClick: logout,
                  },
                ]}
              />
            </Popover>
          </NavStyled.Profile>
        </NavStyled.Group>
      </NavStyled.Wrap>
      {currentUser.phone?.number && (
        <ConfirmPhoneNumberModal
          visible={confirmPhoneNumberModalIsOpen}
          close={() => setConfirmPhoneNumberModalIsOpen(false)}
          userPhone={currentUser.phone.number}
          codeConfirmed={handlePhoneConfirmed}
        />
      )}
      {currentUser.email && (
        <ConfirmEmailModal
          visible={confirmEmailModalisOpen}
          close={() => setConfirmEmailModalIsOpen(false)}
          userEmail={currentUser.email}
          codeConfirmed={handleEmailConfirmed}
        />
      )}
      <Modal icon="help" title={t('need-help')} visible={helpPopoverVisible} close={() => setHelpPopoverVisible(false)}>
        <p>{t('contact-your-manager')}</p>
        <p>
          {t('FAQ-part-one')}{' '}
          <a
            href={`https://intercom.help/usewalter/${
              currentUser.preferedLanguage?.startsWith('fr') ? 'fr' : 'en'
            }/collections/2456056-usewalter-resident-app`}
            target="_blank"
            rel="noreferrer"
          >
            {t('FAQ-part-two')}
          </a>{' '}
          {t('FAQ-part-three')}
        </p>
        <Button onClick={() => setHelpPopoverVisible(false)} theme="primary">
          {t('close')}
        </Button>
      </Modal>
    </NavStyled.Container>
  )
}

function EmergencyContact({
  contact,
  isFirst,
  closePopover,
}: {
  contact: Api.EmergencyContactsQuery['emergencyContacts'][number]
  isFirst: boolean
  closePopover: () => void
}) {
  React.useEffect(() => {
    function closePopoverListener(ev: KeyboardEvent) {
      if (ev.key === 'Escape') {
        closePopover()
      }
    }
    document.addEventListener('keydown', closePopoverListener)

    return () => {
      document.removeEventListener('keydown', closePopoverListener)
    }
  }, [])
  return (
    <Link
      ref={(ref) => {
        if (isFirst) {
          ref?.focus()
        }
      }}
      onClick={(ev) => {
        if (!contact.phone?.number && !contact.email) {
          ev.preventDefault()
        }
      }}
      to={contact.phone?.number ? `tel:${contact.phone.number}` : `mailto:${contact.email}`}
      className="group "
    >
      <div className="hover:bg-border/20 p-2 rounded group-focus-visible:bg-border/20 group-focus-visible:focus-visible:outline-none group-focus-visible:ring-2 group-focus-visible:ring-ring/30 group-focus-visible:ring-offset-1">
        <p className="mb-0 text-sm">{SharedUtils.getUserName(contact)}</p>
        <p className="mb-0 text-sm text-sky-600">
          {contact.phone?.number ? SharedUtils.formatPhoneNumber(contact.phone.number) : contact.email}
        </p>
      </div>
    </Link>
  )
}
